<template>
  <div class="pa-5">
    <v-card elevation="0" class="pa-5">
      <div class="page-title-box d-flex align-items-center justify-content-between">
        <h4 class="mb-0">รายการยาและเวชภัณฑ์</h4>
      </div>
      <div class="d-flex justify-end">
        <v-btn
          :loading="loader"
          :disabled="loader"
          color="blue-grey"
          class="my-3 white--text"
          @click="gotocreate()"
        >
          <v-icon
            left
            dark
          >
            mdi-plus-circle
          </v-icon>
          เพิ่มข้อรายการยาและเวชภัณฑ์
        </v-btn>
      </div>
      <v-card-title class="pt-0 px-0">
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="4">
            <v-text-field
              v-model="filter_search"
              label="พิมพ์คำค้นหา เช่น ชืื่อยา ชื่ออุปกรณ์ รหัสยา"
              single-line
              hide-details
            >
              <v-icon
                slot="prepend"
                color="#182839"
              >
                mdi-magnify
              </v-icon>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="2">
            <div class="d-flex flex-row">
              <!-- <span class="label-search-left">หมวดหมู่</span> -->
              <v-select
                :items="itemsProductTypeFilter"
                v-model="select_filter_type"
                item-text="name"
                item-value="name"
                label="เลืือกหมวดหมู่"
                single-line
                hide-details
              ></v-select>
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="2">
            <div class="d-flex flex-row">
              <!-- <span class="label-search-left">หมวดหมู่</span> -->
              <v-select
                :items="itemsProductGroupFilter"
                v-model="select_filter_group"
                item-text="name"
                item-value="name"
                label="เลืือกกลุ่ม"
                single-line
                hide-details
              ></v-select>
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="2">
            <div class="d-flex flex-row">
              <!-- <span class="label-search-left">หมวดหมู่</span> -->
              <v-select
                :items="itemsProductCategoryFilter"
                v-model="select_category_group"
                item-text="name"
                item-value="name"
                label="เลืือกประเภท"
                single-line
                hide-details
              ></v-select>
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="2">
            <div class="d-flex flex-row">
              <!-- <span class="label-search-left">หมวดหมู่</span> -->
              <v-select
                :items="itemsProductStockCycleFilter"
                v-model="select_stockcycle_group"
                item-text="name"
                item-value="name"
                label="เลืือกรอบสต๊อก"
                single-line
                hide-details
              ></v-select>
            </div>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="data"
        :search="filter_search"
        :loading="loaddata"
        loading-text="กำลังโหลด..."
      >
      <template v-slot:item="{ item }">
        <tr>
          <td>
            <div v-if="item.product_type === 'drug'" class="d-flex flex-row">
              <v-icon class="mr-2">mdi-pill</v-icon>
              <span>ยา</span>
            </div>
            <div v-else class="d-flex flex-row">
              <v-icon class="mr-2">mdi-needle</v-icon>
              <span>เวชภัณฑ์</span>
            </div>
          </td>

          <td class="text-start">{{item.product_code}}</td>
          <td class="text-start">{{item.product_name}}</td>
          <td class="text-start">{{item.product_group_name}}</td>
          <td class="text-start">{{item.product_category}}</td>
          <td class="text-start">{{item.product_brand}}</td>
          <td class="text-start">{{item.product_dealer}}</td>
          <td class="text-start">{{item.product_stock_cycle}}</td>
          <td class="text-end">{{item.product_cost_price}}</td>
          <td class="text-end">{{item.product_sell_price}}</td>
          <!-- <td class="text-end">{{item.product_remain}}</td> -->
          <td>
            <div class="d-flex flex-row align-center justify-space-between">
              <span>{{item.product_remain}}</span>
              <div
                v-if="item.product_remain === 0"
                class="d-flex flex-column align-center justify-center btn-warning-outstock px-3 py-1 ml-3"
              >
                <v-icon color="#FFF" size="18">mdi-alert-circle</v-icon>
                <span class="label-warning-outstock">หมดแล้ว</span>
              </div>
              <div
                v-else-if="item.product_remain <= item.product_alert_remain"
                class="d-flex flex-column align-center justify-center btn-warning-nearoutstock px-3 py-1 ml-3"
              >
                <v-icon color="#FFC107" size="18">mdi-alert-circle</v-icon>
                <span class="label-warning-nearoutstock">ใกล้หมด</span>
              </div>
            </div>
          </td>
          <td class="text-end">{{item.product_unit_name}}</td>

          <td class="text-center">
            <v-switch
              v-model="item.product_status_bool"
              inset
              dense
            ></v-switch>
          </td>

          <td class="text-center">
            <v-btn
              color="secondary"
              fab
              x-small
              dark
              @click="goToAddStock(item)"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </td>

          <td class="text-center">
            <v-btn
              icon
              @click="viewItem(item)"
            >
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <v-btn
              icon
              @click="editItem(item)"
            >
              <v-icon>mdi-file-edit-outline</v-icon>
            </v-btn>
            <v-btn
              icon
              @click="deleteItem(item)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>

      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="initData"
        >
          รีเซ็ต
        </v-btn>
      </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="showDialog" max-width="500px">
      <v-card>
        <v-card-title>{{boolViewItems ? 'ข้อมูลยาและเวชภัณฑ์' : boolEditItems ? 'แก้ไขข้อมูลยาและเวชภัณฑ์' : 'ลบข้อมูลยาและเวชภัณฑ์'}}</v-card-title>
        <v-card-text v-if="boolViewItems">
          <v-row class="mt-3 pa-0">
            <v-col cols="12" sm="12" md="4" lg="4">
              <span class="label-drudsandmedical-add-stock-incard">หมวดหมู่:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <div v-if="dataDialog.product_type === 'drug'" class="d-flex flex-row">
                <v-icon class="mr-2">mdi-pill</v-icon>
                <span>ยา</span>
              </div>
              <div v-else class="d-flex flex-row">
                <v-icon class="mr-2">mdi-needle</v-icon>
                <span>เวชภัณฑ์</span>
              </div>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4">
              <span class="label-drudsandmedical-add-stock-incard">รหัส:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <span>{{dataDialog.product_code}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4">
              <span class="label-drudsandmedical-add-stock-incard">ชื่อ:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <span>{{dataDialog.product_name}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4">
              <span class="label-drudsandmedical-add-stock-incard">กลุ่ม:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <span>{{dataDialog.product_group_name}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4">
              <span class="label-drudsandmedical-add-stock-incard">ประเภท:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <span>{{dataDialog.product_category}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4">
              <span class="label-drudsandmedical-add-stock-incard">ยี่ห้อ:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <span>{{dataDialog.product_brand}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4">
              <span class="label-drudsandmedical-add-stock-incard">ผู้จำหน่าย:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <span>{{dataDialog.product_dealer}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4">
              <span class="label-drudsandmedical-add-stock-incard">รอบสต๊อก:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <span>{{dataDialog.product_stock_cycle}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4">
              <span class="label-drudsandmedical-add-stock-incard">ราคาทุน:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <span>{{dataDialog.product_cost_price}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4">
              <span class="label-drudsandmedical-add-stock-incard">ราคาขาย:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <span>{{dataDialog.product_sell_price}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4">
              <span class="label-drudsandmedical-add-stock-incard">คงเหลือ:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <span>{{dataDialog.product_remain}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4">
              <span class="label-drudsandmedical-add-stock-incard">หน่วยนับ:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <span>{{dataDialog.product_unit_name}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">สถานะ:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8" class="d-flex align-center">
              <v-switch
                v-model="dataDialog.product_status_bool"
                inset
                dense
                disabled
              ></v-switch>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-else-if="boolEditItems">
          <v-row class="mt-3 pa-0">
            <v-col cols="12" sm="12" md="4" lg="4" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">หมวดหมู่:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8" class="d-flex align-center">
              <v-select
                :items="itemsProductType"
                v-model="dataDialog.product_type"
                item-text="name"
                item-value="value"
                label="เลือกหมวดหมู่"
                outlined
                single-line
                hide-details
              ></v-select>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">รหัส:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8" class="d-flex align-center">
              <v-text-field
                v-model="dataDialog.product_code"
                label="รหัส"
                placeholder="ระบุรหัส"
                outlined
                single-line
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">ชื่อ:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8" class="d-flex align-center">
              <v-text-field
                v-model="dataDialog.product_name"
                label="รหัส"
                placeholder="ระบุรหัส"
                outlined
                single-line
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">กลุ่ม:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8" class="d-flex align-center">
              <v-select
                :items="itemsGroupName"
                v-model="dataDialog.product_group_name"
                item-text="name"
                item-value="value"
                label="เลือกกลุ่ม"
                outlined
                single-line
                hide-details
              ></v-select>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">ประเภท:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8" class="d-flex align-center">
              <v-select
                :items="itemsCategoryDrug"
                v-model="dataDialog.product_category"
                item-text="name"
                item-value="value"
                label="เลือกประเภท"
                outlined
                single-line
                hide-details
              ></v-select>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">ยี่ห้อ:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8" class="d-flex align-center">
              <v-select
                :items="itemsBrand"
                v-model="dataDialog.product_brand"
                item-text="name"
                item-value="value"
                label="เลือกยี่ห้อ"
                outlined
                single-line
                hide-details
              ></v-select>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">ผู้จำหน่าย:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8" class="d-flex align-center">
              <v-select
                :items="itemsDealer"
                v-model="dataDialog.product_dealer"
                item-text="name"
                item-value="value"
                label="เลือกผู้จำหน่าย"
                outlined
                single-line
                hide-details
              ></v-select>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">รอบสต๊อก:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8" class="d-flex align-center">
              <v-select
                :items="itemsStockCycle"
                v-model="dataDialog.product_stock_cycle"
                item-text="name"
                item-value="value"
                label="เลือกรอบสต๊อก"
                outlined
                single-line
                hide-details
              ></v-select>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">ราคาทุน:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8" class="d-flex align-center">
              <v-text-field
                v-model="dataDialog.product_cost_price"
                label="ราคาทุน"
                placeholder="ระบุราคาทุน"
                suffix="บาท"
                outlined
                single-line
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">ราคาขาย:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8" class="d-flex align-center">
              <v-text-field
                v-model="dataDialog.product_sell_price"
                label="ราคาขาย"
                placeholder="ระบุราคาขาย"
                suffix="บาท"
                outlined
                single-line
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">คงเหลือ:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8" class="d-flex align-center">
              <v-text-field
                v-model="dataDialog.product_remain"
                label="คงเหลือ"
                placeholder="ระบุคงเหลือ"
                :suffix="dataDialog.product_unit_name"
                outlined
                single-line
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">หน่วยนับ:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8" class="d-flex align-center">
              <v-text-field
                v-model="dataDialog.product_unit_name"
                label="หน่วยนับ"
                placeholder="ระบุหน่วยนับ"
                outlined
                single-line
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">สถานะ:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8" class="d-flex align-center">
              <v-switch
                v-model="dataDialog.product_status_bool"
                inset
                dense
              ></v-switch>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-else>
          <span>{{`ต้องการลบรายการ ${dataDialog.product_name || ''} ใช่หรือไม่`}}</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="!boolViewItems" color="error" text @click="closeDialog">ยกเลิก</v-btn>
          <v-btn :color="boolViewItems ? 'blue darken-1' : 'success'" text @click="submitDialog">{{boolViewItems ? 'ปิด' : 'ยืนยัน'}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import swal from 'sweetalert'

export default {
  data () {
    return {
      loader: null,
      loaddata: false,
      cansave: true,
      filter_search: '',
      select_filter_type: '',
      select_filter_group: '',
      select_category_group: '',
      select_stockcycle_group: '',
      showDialog: false,
      dataDialog: {},
      boolViewItems: false,
      boolEditItems: false,
      boolDelItems: false,
      data: [
        {
          _id: '0001',
          product_type: 'drug',
          product_code: 'M100001',
          product_name: 'Botox กราม (0.7)',
          product_group_name: 'Botox',
          product_category: 'หน้า',
          product_brand: 'ไม่ระบุ',
          product_dealer: 'ไม่ระบุ',
          product_stock_cycle: 'L00051',
          product_cost_price: 1500,
          product_sell_price: 2999,
          product_remain: 50000,
          product_alert_remain: 5000,
          product_unit_name: 'คู่',
          product_status: 'active',
          product_status_bool: true
        },
        {
          _id: '0002',
          product_type: 'drug',
          product_code: 'M100001',
          product_name: 'Botox กราม (0.7)',
          product_group_name: 'Botox',
          product_category: 'หน้า',
          product_brand: 'ไม่ระบุ',
          product_dealer: 'ไม่ระบุ',
          product_stock_cycle: 'L00051',
          product_cost_price: 1500,
          product_sell_price: 2999,
          product_remain: 0,
          product_alert_remain: 500,
          product_unit_name: 'คู่',
          product_status: 'active',
          product_status_bool: true
        },
        {
          _id: '0003',
          product_type: 'drug',
          product_code: 'M100001',
          product_name: 'Botox กราม (0.7)',
          product_group_name: 'Botox',
          product_category: 'หน้า',
          product_brand: 'ไม่ระบุ',
          product_dealer: 'ไม่ระบุ',
          product_stock_cycle: 'L00051',
          product_cost_price: 1500,
          product_sell_price: 2999,
          product_remain: 1000,
          product_alert_remain: 5000,
          product_unit_name: 'คู่',
          product_status: 'inactive',
          product_status_bool: false
        },
        {
          _id: '0004',
          product_type: 'drug',
          product_code: 'M100001',
          product_name: 'Botox กราม (0.7)',
          product_group_name: 'Botox',
          product_category: 'หน้า',
          product_brand: 'ไม่ระบุ',
          product_dealer: 'ไม่ระบุ',
          product_stock_cycle: 'L00051',
          product_cost_price: 1500,
          product_sell_price: 2999,
          product_remain: 50000,
          product_alert_remain: 1000,
          product_unit_name: 'คู่',
          product_status: 'active',
          product_status_bool: true
        },
        {
          _id: '0005',
          product_type: 'medical',
          product_code: 'E100001',
          product_name: 'Tube PRP (หลอด)',
          product_group_name: 'วิตามินผิว',
          product_category: 'เร่งขาว',
          product_brand: 'NIPRO',
          product_dealer: 'ไม่ระบุ',
          product_stock_cycle: 'L00051',
          product_cost_price: 20,
          product_sell_price: '-',
          product_remain: 400,
          product_alert_remain: 500,
          product_unit_name: 'หลอด',
          product_status: 'active',
          product_status_bool: true
        },
        {
          _id: '0006',
          product_type: 'medical',
          product_code: 'E100002',
          product_name: 'ยาชาแบบทา',
          product_group_name: 'ยาชา',
          product_category: 'แบบทา',
          product_brand: 'ไม่ระบุ',
          product_dealer: 'ไม่ระบุ',
          product_stock_cycle: 'L00051',
          product_cost_price: 700,
          product_sell_price: '-',
          product_remain: 356,
          product_alert_remain: 400,
          product_unit_name: 'กระปุก',
          product_status: 'active',
          product_status_bool: true
        }
      ],
      itemsProductTypeFilter: [],
      itemsProductGroupFilter: [],
      itemsProductCategoryFilter: [],
      itemsProductStockCycleFilter: []
    }
  },
  computed: {
    headers () {
      return [
        {
          text: 'หมวดหมู่',
          align: 'center',
          sortable: false,
          value: 'product_type'
        }, {
          text: 'รหัส',
          align: 'center',
          value: 'product_code',
          sortable: false
        }, {
          text: 'ชื่อ',
          align: 'center',
          value: 'product_name',
          sortable: false
        }, {
          text: 'กลุ่ม',
          align: 'center',
          value: 'product_group_name',
          sortable: false
        }, {
          text: 'ประเภท',
          align: 'center',
          value: 'product_category',
          sortable: false
        }, {
          text: 'ยี่ห้อ',
          align: 'center',
          value: 'product_brand',
          sortable: false
        }, {
          text: 'ผู้จำหน่าย',
          align: 'center',
          value: 'product_dealer',
          sortable: false
        }, {
          text: 'รอบสต๊อก',
          align: 'center',
          value: 'product_stock_cycle',
          sortable: false
        }, {
          text: 'ราคาทุน',
          align: 'center',
          value: 'product_cost_price',
          sortable: false
        }, {
          text: 'ราคาขาย',
          align: 'center',
          value: 'product_sell_price',
          sortable: false
        }, {
          text: 'คงเหลือ',
          align: 'center',
          value: 'product_remain',
          sortable: false
        }, {
          text: 'หน่วยนับ',
          align: 'center',
          value: 'product_unit_name',
          sortable: false
        }, {
          text: 'สถานะ',
          align: 'center',
          value: 'product_status',
          sortable: false
        }, {
          text: 'เพิ่มสต๊อก',
          align: 'center',
          value: 'add',
          sortable: false
        }, {
          text: 'จัดการ',
          align: 'center',
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  watch: {
    select_filter_type () {
      const self = this
      if (self.select_filter_type === 'ทั้งหมด') {
        if (self.select_filter_group) {
          self.filter_search = self.select_filter_group
        } else self.filter_search = ''
      } else {
        // if (self.select_filter_group) self.filter_search = `${self.select_filter_type}, ${self.select_filter_group}`
        // else self.filter_search = self.select_filter_type
        self.filter_search = self.select_filter_type
      }
    }
  },
  created () {
    this.initData()
  },
  methods: {
    initData () {
      const self = this
      self.fetchType()
    },
    async fetchType () {
      const self = this
      var tempFilter = []
      try {
        const res = await self.axios.get(process.env.VUE_APP_API + '/product/category', {
          headers: {
            Authorization: localStorage.getItem('Token') || self.$store.state.token
          }
        })
        if (res.status === 200) {
          res.data.result.data.map(el => {
            tempFilter.push({
              _id: el._id,
              name: el.category,
              value: el._id,
              created_at: el.create_at
            })
          })
          tempFilter.sort((a, b) => {
            if (a.name < b.name) { return -1 }
            if (a.name > b.name) { return 1 }
            return 0
          })
          tempFilter.unshift({
            _id: '0',
            name: 'ทั้งหมด',
            value: ''
          })
        }
      } catch (error) {
        console.log(error.message)
      } finally {
        self.itemsProductTypeFilter = tempFilter
        self.fetchGroup()
      }
    },
    async fetchGroup () {
      const self = this
      var tempFilter = []
      try {
        const res = await self.axios.get(process.env.VUE_APP_API + '/product/group', {
          headers: {
            Authorization: localStorage.getItem('Token') || self.$store.state.token
          }
        })
        if (res.status === 200) {
          res.data.result.data.map(el => {
            tempFilter.push({
              _id: el._id,
              name: el.group,
              value: el._id,
              created_at: el.create_at
            })
          })
          tempFilter.sort((a, b) => {
            if (a.name < b.name) { return -1 }
            if (a.name > b.name) { return 1 }
            return 0
          })
          tempFilter.unshift({
            _id: '0',
            name: 'ทั้งหมด',
            value: ''
          })
        }
      } catch (error) {
        console.log(error.message)
      } finally {
        self.itemsProductGroupFilter = tempFilter
        self.fetchCategory()
      }
    },
    async fetchCategory () {
      const self = this
      var tempFilter = []
      try {
        const res = await self.axios.get(process.env.VUE_APP_API + '/product/type', {
          headers: {
            Authorization: localStorage.getItem('Token') || self.$store.state.token
          }
        })
        if (res.status === 200) {
          res.data.result.data.map(el => {
            tempFilter.push({
              _id: el._id,
              name: el.type,
              value: el._id,
              created_at: el.create_at
            })
          })
          tempFilter.sort((a, b) => {
            if (a.name < b.name) { return -1 }
            if (a.name > b.name) { return 1 }
            return 0
          })
          tempFilter.unshift({
            _id: '0',
            name: 'ทั้งหมด',
            value: ''
          })
        }
      } catch (error) {
        console.log(error.message)
      } finally {
        self.itemsProductCategoryFilter = tempFilter
        self.fetchStockCycle()
      }
    },
    async fetchStockCycle () {
      const self = this
      var tempFilter = []
      try {
        const res = await self.axios.get(process.env.VUE_APP_API + '/stock/round', {
          headers: {
            Authorization: localStorage.getItem('Token') || self.$store.state.token
          }
        })
        if (res.status === 200) {
          res.data.result.data.map(el => {
            tempFilter.push({
              _id: el._id,
              name: el.round_code,
              value: el._id,
              created_at: el.create_at
            })
          })
          tempFilter.sort((a, b) => {
            if (a.name < b.name) { return -1 }
            if (a.name > b.name) { return 1 }
            return 0
          })
          tempFilter.unshift({
            _id: '0',
            name: 'ทั้งหมด',
            value: ''
          })
        }
      } catch (error) {
        console.log(error.message)
      } finally {
        self.itemsProductStockCycleFilter = tempFilter
        self.fetchData()
      }
    },
    async fetchData () {
      const self = this
      self.loaddata = true
      var temp = []
      try {
        const res = await self.axios.get(process.env.VUE_APP_API + '/product', {
          headers: {
            Authorization: localStorage.getItem('Token') || self.$store.state.token
          }
        })
        if (res.status === 200) {
          // console.log(res)
          res.data.result.data.map(el => {
            // self.itemsProductTypeCreate.map(e => {
            //   self.itemsProductGroupCreate.map(ele => {
            //     if (e._id === el.category._id && ele._id === el.group._id) {
            //       temp.push({
            //         _id: el._id,
            //         product_category: el.type,
            //         product_group_name: ele.name,
            //         product_group_value: ele.value,
            //         product_type: e.name,
            //         product_type_value: e.value,
            //         product_status: el.active ? 'active' : 'inactive',
            //         product_status_bool: el.active,
            //         created_at: el.create_at
            //       })
            //     }
            //   })
            // })
            // temp.push({
            //   _id: el._id,
            //   product_category: el.type,
            //   product_group_name: ele.name,
            //   product_group_value: ele.value,
            //   product_type: e.name,
            //   product_type_value: e.value,
            //   product_status: el.active ? 'active' : 'inactive',
            //   product_status_bool: el.active,
            //   created_at: el.create_at
            // })
          })
          // temp.sort((a, b) => {
          //   if (a.product_category < b.product_category) { return -1 }
          //   if (a.product_category > b.product_category) { return 1 }
          //   return 0
          // })
        }
      } catch (error) {
        console.log(error.message)
      } finally {
        console.log(temp)
        // self.data = temp
        self.loaddata = false
      }
    },
    gotocreate () {
      const self = this
      self.loader = 'loader'
      setTimeout(() => {
        self.loader = null
        self.$router.push({ name: 'DrugsAndMedicalCreateScreen' })
      }, 200)
    },
    filterOnlyCapsText (value, search, item) {
      return value != null &&
        search != null &&
        typeof value === 'string' &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
    },
    goToAddStock (item) {
      const self = this
      setTimeout(() => {
        self.$router.push({ name: 'DrugsAndMedicalAddToStockScreen', params: item })
      }, 200)
    },
    viewItem (item) {
      const self = this
      self.dataDialog = item
      self.boolViewItems = true
      self.showDialog = true
    },
    editItem (item) {
      const self = this
      self.dataDialog = item
      self.boolEditItems = true
      self.showDialog = true
    },
    deleteItem (item) {
      const self = this
      self.dataDialog = item
      self.boolDelItems = true
      self.showDialog = true
    },
    closeDialog () {
      const self = this
      self.boolViewItems = false
      self.boolEditItems = false
      self.boolDelItems = false
      self.showDialog = false
      self.dataDialog = {}
    },
    submitDialog () {
      const self = this
      if (self.boolEditItems) {
        self.closeDialog()
        setTimeout(() => {
          swal('สำเร็จ', 'แก้ไขรายการยาหรือเวชภัณฑ์สำเร็จ', 'success', {
            button: false,
            timer: 3000
          }).then(() => {
          })
        }, 200)
      } else if (self.boolDelItems) {
        self.closeDialog()
        setTimeout(() => {
          swal('สำเร็จ', 'ลบรายการยาหรือเวชภัณฑ์สำเร็จ', 'success', {
            button: false,
            timer: 3000
          }).then(() => {
          })
        }, 200)
      } else {
        self.closeDialog()
      }
    }
  }
}
</script>
